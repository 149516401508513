<template>
    <div class="main pagephoto">
        <my-header :title="photos_edit_data.id>0?`${photos_edit_data.title}`:'加载中...'"></my-header>
        <van-skeleton title :row="20" :loading="loading">
            <div :class="['padding','photos','van-fade',{'nophotos':photos_edit_data.photos_obj==0}]">
                <div class="center upload">
                    <van-uploader class="photo" :preview-image="true" multiple lazy-load :max-count="30" preview-size="94" :max-size="5000*1024" @oversize="onOversize" v-model="photos_edit_data.photos_obj" :before-delete="photoDelConfirm" @delete="photoDel" :after-read="photoUpload">
                    </van-uploader>
                </div>
            </div>
        </van-skeleton>
    </div>
</template>
<script>
import { Wiki } from "@/api/wiki.js";
import { Upload } from "@/api/upload.js";

export default {
    components: {
        [Wiki.name]: Wiki,
    },
    data() {
        return {
            loading: true,
            id: this.$route.params.id,
            photos_edit_data: { id: 0, photos_obj: [] },
            conf:this.$store.get('conf')
        };
    },
    created: function() { this.getDetail() },
    methods: {
        //获取详情数据
        getDetail() {
            Wiki.detail({ 'id': this.id, 'showloading': true })
                .then(res => {
                    this.loading = false;
                    if (res) {
                        this.photos_edit_data.id = this.id;
                        this.photos_edit_data.title = res.data.title;
                        this.photos_edit_data.photos = res.data.photos;
                        this.photos_edit_data.photos_obj = res.data.photos.map(function(item) {
                            return { url: item, src: item };
                        });
                    }
                })
        },
        //上传文件过大
        onOversize() {
            this.$toast('文件大小超过限制');
        },
        //是否删除相册
        photoDelConfirm() {
            return this.$dialog.confirm({
                title: '确定删除吗',
                message: '删除后不可以恢复',
            })
        },
        //删除cos中图片
        photoDel(file) {
            Upload.uploadImg_cos_del({ src: file.src, id: this.photos_edit_data.id }).then(res => {
                if (res.errcode == 0) {
                    return true;
                }
            }).catch(() => {
                return false;
            });
        },
        //上传相册图到对象存储cos
        photoUpload(file) {
            var file_arr = file.length > 0 ? file : [file];
            for (let i = 0; i < file_arr.length; i++) {
                let param = new FormData();
                param.append("file", file_arr[i].file);
                param.append("id", this.photos_edit_data.id);
                file_arr[i].status = 'uploading';
                Upload.uploadImg_cos(param).then(res => {
                    if (res.code == 1) {
                        file_arr[i].status = 'done';
                        file_arr[i].src = res.data;
                    } else {
                        file_arr[i].status = 'failed';
                    }

                }).catch(() => {
                    file_arr[i].status = 'failed';
                });
            }
        },
    },
    computed: {

        //显示详细信息

    },
    watch: {}
};
//允许双指缩放
document.documentElement.addEventListener('touchstart', function(e) {
    e.returnValue = true;
}, false);
</script>
<style lang="less">
</style>